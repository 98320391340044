<template>
  <div>

    <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="login-ruleForm">

      <el-form-item label="用户" prop="user">
        <el-input  v-model="ruleForm.user" ></el-input>
      </el-form-item>

      <el-form-item label="密码" prop="password">
        <el-input type="password" v-model="ruleForm.password" ></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">登录</el-button>

        <el-button @click="resetForm('ruleForm')">重置</el-button>
      </el-form-item>
    </el-form>


  </div>

</template>

<script>
import { loginApi } from '@/api/user'
import { setToken ,setData } from '@/utils/cache'
export default {
  name: "login",
  data(){

    return{
      url:process.env.VUE_APP_BASE_API,
      ruleForm: {
        user: '',
        password: '',
      },
      rules: {
        user: [{ required: true, message: '用户不能为空', trigger: 'blur' }],
        password: [{ required: true, message: '密码不能为空', trigger: 'blur' }],
      }
    }
  },
  methods:{
    login(){
      this.$router.push({path:'/mainxx' })

    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {

          loginApi({name: this.ruleForm.user,pwd: this.ruleForm.password,}).then(response => {
            console.info(response)

            if(response.body){
              console.info(response.body.token)
              setToken(response.body.token)
              setData("uname",response.body.name)
              localStorage.setItem("roleId",response.body.roleId);
              this.$router.push({path:'/welcome' });

            }else {
              this.$message({
                showClose: true,
                message: '登录失败，请检查账户密码是否正确',
                type: 'error'
              });
            }
          })

        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }

  }



}
</script>

<style scoped>
.login-ruleForm{

  margin: 150px auto;
  width: 500px  ;

}
</style>
